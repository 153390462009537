import { IJobCandidate } from '@/services/jobcandidates.service'
import WhiteButton from '@/components/buttons/WhiteButton'
import { RecruitSourceBadge, RecruitStepBadge } from '@/components/profile/person/recruits/Recruit'

import { useCallback, useMemo, useState } from 'react'
import SlideOver from '@/components/slideover/SlideOver'
import NotesTab from '@/components/notes/NotesTab'
import { PersonPage } from '@/pages/Person'
import { NoteTables } from '@/services'
import { toast } from 'react-toastify'
import BlueLinkButton from '@/components/links/BlueButtonLink'
import { ExternalLinkIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

const RecruitInner = ({ jobCandidate, showCandidatesButton, showRecruitedBy = true }: { jobCandidate: IJobCandidate, showCandidatesButton: boolean, showRecruitedBy?: boolean }) => {
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false)
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)

  const objectDetail = jobCandidate

  const showCandidateDetails = useCallback(() => {
    if (jobCandidate.candidate) {
      setIsUserDialogOpen(true)
    } else {
      toast.info('This candidate was added as an external candidate and doesn\'t have a profile')
    }
  }, [jobCandidate])

  const inRecruiterPipeline = useMemo(() => !!jobCandidate?.recruiting_process?.company, [jobCandidate])

  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="space-y-1">
          <div className="flex text-sm">
            <button
              onClick={() => showCandidateDetails()} 
              className="font-bold text-teal-600 truncate"
            >
              {jobCandidate.candidate ? `${jobCandidate.candidate.first_name} ${jobCandidate.candidate.last_name}` : jobCandidate.candidate_name}
            </button>
            <RecruitStepBadge step={jobCandidate.recruiting_step} />
            <RecruitSourceBadge source={jobCandidate.source} />
          </div>
          {(showRecruitedBy && jobCandidate.candidate && jobCandidate.candidate.id !== jobCandidate.created_by.person.id) && <div className="flex items-center text-sm text-gray-500">
            <SpeakerphoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <p>
              <span className="font-medium">Recruited by&nbsp;</span>
              <span className="text-teal-600 font-semibold">
                <Link to={{
                  pathname: `/people/${jobCandidate.created_by.person.id}`,
                  state: { from: `/companies/${jobCandidate.job.company.id}/job/${jobCandidate.job.id}` }
                }}>
                  {jobCandidate.created_by.person.first_name} {jobCandidate.created_by.person.last_name}
                </Link>
              </span>
            </p>
          </div>}
          {!jobCandidate.candidate && <div className="flex items-center text-sm text-gray-500">
            <ExternalLinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <p className="font-medium">External Candidate</p>
          </div>}
        </div>
      </div>
      <div className="ml-2 sm:ml-0 flex flex-row items-center gap-2">
        <SlideOver
          title={`Notes of Candidate: ${objectDetail.candidate ? objectDetail.candidate.first_name + ' ' + objectDetail.candidate.last_name : objectDetail.candidate_name}`}
          open={isNotesDialogOpen}
          setOpen={setIsNotesDialogOpen}
          hasForm={false}
          loading={false}
        >
          <NotesTab
            noteTable={NoteTables.JobCandidate}
            object={jobCandidate}
            createdByAnybody={true}
            alterValues={{
              job: jobCandidate.job.id,
              company: jobCandidate.job.company_id
            }}
          />
        </SlideOver>

        <WhiteButton
          onClick={() => setIsNotesDialogOpen(true)}           
          title="Notes"
        />

        {(showCandidatesButton && inRecruiterPipeline) && <BlueLinkButton
          title="Pipeline"
          to={`/jobs/${jobCandidate.job.id}/candidates`}
        />}

        {(showCandidatesButton && !inRecruiterPipeline) && <BlueLinkButton
          title="Pipeline (Own)"
          to={`/recruit/pipeline/${jobCandidate.recruiting_process?.id}`}
        />}

        {objectDetail.candidate && <SlideOver
          title={`Profile of ${objectDetail.candidate.first_name} ${objectDetail.candidate.last_name}`}
          open={isUserDialogOpen}
          setOpen={setIsUserDialogOpen}
          hasForm={false}
          loading={false}
        >
          <PersonPage id={String(jobCandidate.candidate.id)} />
        </SlideOver>}
      </div>
    </div>
  )
}

interface IRecruitProps {
  object: IJobCandidate,
  showCandidatesButton: boolean
}

const Recruit = ({ object, ...props }: IRecruitProps) => {
  return (
    <li>
      <RecruitInner jobCandidate={object} {...props} />
    </li>
  )
}

export default Recruit
