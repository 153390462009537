import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import update from 'immutability-helper'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useRecoilValue } from 'recoil';

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { IJob, jobsService } from '@/services/jobs.service'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import { RecruitingProcessStep } from '@/components/recruit/RecruitingProcessStep'
import { IJobCandidate, IRecruitingProcess, IRecruitingStep } from '@/recoil/types'
import { RecruitingProcessStepDetail } from '@/components/recruit/RecruitingProcessStepDetail';
import { HorizontalShadowScrollbars } from '@/components/utils';
import { AccountRoles, jobCandidatesService, recruitmentService } from '@/services'
import { toast } from 'react-toastify';
import { RecruitingProcessStepHeader } from '@/components/recruit/RecruitingProcessStepHeader';
import { RecruitingProcessStepCandidates } from '@/components/recruit/RecruitingProcessStepCandidates';
import { BookOpenIcon, OfficeBuildingIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
import { userState } from '@/recoil/atoms/auth';

const CompanyJobCandidates = () => {
  const MAX_CANDIDATES = 5

  let { job_id } = useParams<{ job_id: string }>()

  const location = useLocation<any>()

  const userData = useRecoilValue(userState)
  const scrollbarsRef = useRef<Scrollbars>(null)
  const [job, setJob] = useState<IJob>()
  const [process, setProcess] = useState<IRecruitingProcess>()
  const [candidates, setCandidates] = useState<IJobCandidate[]>([])
  const [selectedStep, setSelectedStep] = useState<IRecruitingStep>()
  const [isSettingCandidateStep, setIsSettingCandidateStep] = useState(false)
  const [loading, setLoading] = useState(true)
  const [scrollHeight, setScrollHeight] = useState('100vh')

  const history = useHistory()

  const hasBack = (location?.state?.from !== undefined)

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    setLoading(true)
    jobsService.detail(parseInt(job_id), null).then((res) => {
      setJob(res.data)
      if(!res.data?.recruiting_process?.id) {
        setLoading(false);
        return;
      }
      recruitmentService.getProcessDetails(res.data?.recruiting_process?.id, null).then((res) => {
        setProcess(res.data)

        jobsService.getCandidates(parseInt(job_id), null).then((res) => {
          setCandidates(res.data)
          setLoading(false)
        }).catch(() => {
          toast.error("Failed to fetch details")
        })
      }).catch(() => {
        toast.error("Failed to fetch details")
      })
    }).catch(() => {
      toast.error("Failed to fetch details")
    })
  }, [job_id])

  const candidatesForStep = useCallback((stepId: number) => {
    return candidates.filter((candidate) => candidate.recruiting_step_id === stepId).sort(
      (a, b) => (a.candidate ? a.candidate.last_name : (a.candidate_name ?? '')).localeCompare(b.candidate ? b.candidate.last_name : (b.candidate_name ?? ''))
    )
  }, [candidates])

  const candidatesForStepFromGrouping = (stepId: number, candidates: IJobCandidate[]) => {
    return candidates.filter((candidate) => candidate.recruiting_step_id === stepId).sort(
      (a, b) => (a.candidate ? a.candidate.last_name : (a.candidate_name ?? '')).localeCompare(b.candidate ? b.candidate.last_name : (b.candidate_name ?? ''))
    )
  }

  const groupings = useMemo(() => {
    const _groupings: { [key: string]: IJobCandidate[] } = {}
    for (const candidate of candidates) {
      if (!candidate.candidate || candidate.created_by.person.id === candidate.candidate.id) {
        _groupings['NO:No Recruiter'] = [...(_groupings['NO:No Recruiter'] ?? []), candidate]
      } else if (candidate.created_by.company) {
        _groupings[`CO:${candidate.created_by.company.name}`] = [...(_groupings[`CO:${candidate.created_by.company.name}`] ?? []), candidate]
      } else {
        const recruiterName = `${candidate.created_by.person.first_name} ${candidate.created_by.person.last_name}`
        _groupings[`RE:${recruiterName}`] = [...(_groupings[`RE:${recruiterName}`] ?? []), candidate]
      }
    }
    return _groupings
  }, [candidates])

  const reflectCandidateUpdate = useCallback((stepId: number, candidateId: number) => {
    const foundIndex = candidates.findIndex((candidate) => candidate.id === candidateId)
    if (foundIndex >= 0) {
      updateCandidateStep(candidateId, stepId)
      setCandidates(
        update(candidates, {
          [foundIndex]: {
            recruiting_step_id: {
              $set: stepId,
            }
          }
        })
      )
    }
  }, [candidates])

  const handleDrop = useCallback((stepId: number, item: { candidateId: number }) => {
    reflectCandidateUpdate(stepId, item.candidateId)
  }, [reflectCandidateUpdate])

  const updateCandidateStep = (candidateId: number, stepId: number) => {
    setIsSettingCandidateStep(true)
    jobCandidatesService.updateStep(candidateId, stepId).then(() => {
      setIsSettingCandidateStep(false)
    }).catch(() => {
      setIsSettingCandidateStep(false)
      toast.error("Failed to update the candidate's step. You may not have permission to perform this action.")
    })
  }

  const rejectAcceptTypes = useMemo(() => {
    if (!process) {
      return []
    }

    const types: string[] = []

    for (const groupingName of Object.keys(groupings)) {
      types.push(...process.steps.filter((step) => step.order >= 0).map((step) => `order_${step.order}_${groupingName}`))
    }

    if (userData?.role === AccountRoles.COMPANY && userData?.company?.id === job?.company?.id) {
      for (const groupingName of Object.keys(groupings)) {
        types.push(`any_${groupingName}`)
      }
    }

    return types
  }, [groupings, job?.company?.id, process, userData?.company?.id, userData?.role])

  useEffect(() => {
    const containerEl = scrollbarsRef.current?.container.firstElementChild
    const start = containerEl?.firstElementChild?.getBoundingClientRect().top
    const endTop = containerEl?.lastElementChild?.getBoundingClientRect().top
    const endHeight = containerEl?.lastElementChild?.getBoundingClientRect().height

    if (start !== undefined && endTop !== undefined && endHeight !== undefined) {
      let height = (endTop + endHeight) - start
      height += 3 // account for last row's shadow
      setScrollHeight(`${height}px`)
    }
  }, [loading, selectedStep])

  const getGroupingNameForCandidate = useCallback((candidate: IJobCandidate) => {
    if (!candidate.candidate || candidate.created_by.person.id === candidate.candidate.id) {
      return 'NO:No Recruiter'
    } else if (candidate.created_by.company) {
      return `CO:${candidate.created_by.company.name}`
    } else {
      return `RE:${candidate.created_by.person.first_name} ${candidate.created_by.person.last_name}`
    }
  }, [])

  return (
    <Layout title="Job Candidates" auth={true} back={true} sidebar={false}>
      {!loading ?
        <>
          {hasBack && <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
            <button
              onClick={goBack}
              className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
            >
              <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
              <span>Back</span>
            </button>
          </nav>}

          <div className="mb-4">
            <HeadingsH2 heading={`Candidates for ${job?.published_version?.title ?? job?.latest_version?.title}`} className="truncate" />
            {!job?.open && <div className="flex items-center text-sm text-gray-500 font-bold mt-2">
              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Job Closed
            </div>}
          </div>

          <div className="sm:space-x-4 px-1 mb-4 gap-4 flex flex-col sm:block">
            {process?.steps.filter((step) => step.order < 0).map((step) => (
              <RecruitingProcessStep
                onStepClick={() => {setSelectedStep(selectedStep?.id === step.id ? undefined : step)}}
                step={step}
                candidates={candidatesForStep(step.id)}
                onDrop={(item) => handleDrop(step.id, item)}
                maxCandidates={!!selectedStep ? 0 : MAX_CANDIDATES}
                isSelected={selectedStep?.id === step.id}
                anySelected={selectedStep !== undefined}
                disabled={job?.open !== true}
                loading={isSettingCandidateStep}
                key={step.id}
                canManageCandidates={step.can_manage_candidates}
                groupingName={'reject'}
                hasHiringPerm={userData?.role === AccountRoles.HIRING_MANAGER && userData?.company?.id === job?.company?.id}
                anyOverride={userData?.role === AccountRoles.COMPANY && userData?.company?.id === job?.company?.id}
                acceptTypes={rejectAcceptTypes}
                getGroupingNameForCandidate={getGroupingNameForCandidate}
              />
            ))}
          </div>

          <HorizontalShadowScrollbars
            ref={scrollbarsRef}
            className="overflow-x-auto whitespace-nowrap pt-2"
            style={{ width: '100%', height: selectedStep !== undefined ? '132px' : scrollHeight }}
            renderTrackHorizontal={({ style, ...props }) =>
              <div {...props} style={{ 
                ...style,
                top: 0,
                right: 4,
                left: 4,
                borderRadius: 3,
                zIndex: 10
              }} />
            }
            onWheel={(e) => {
              const wrapper = document.getElementById('infinite-scroll-wrapper')
              if (wrapper && wrapper.scrollHeight <= wrapper.clientHeight) {
                scrollbarsRef.current && scrollbarsRef.current.scrollLeft(scrollbarsRef.current.getScrollLeft() + e.deltaY)
              }
            }}
          >
            {selectedStep === undefined ? <>
              <div className="pt-4 space-x-4 px-1">
                {process?.steps.filter((step) => step.order >= 0).map((step) => (
                  <RecruitingProcessStepHeader
                    onStepClick={() => {setSelectedStep(step)}}
                    step={step}
                    isSelected={false}
                    anySelected={selectedStep !== undefined}
                    key={step.id}
                  />
                ))}
              </div>
              {Object.entries(groupings).sort((a, b) => a[0].localeCompare(b[0])).map((grouping) => (
                <>
                  <div key={`${grouping[0]} title`} className="sticky left-2 pt-4 pb-2 pl-2 font-bold text-gray-500 text-sm flex gap-1 items-center">
                    {grouping[0].startsWith('NO:') && <BookOpenIcon className="w-4 h-4" aria-hidden="true" />}
                    {grouping[0].startsWith('CO:') && <OfficeBuildingIcon className="w-4 h-4" aria-hidden="true" />}
                    {grouping[0].startsWith('RE:') && <SpeakerphoneIcon className="w-4 h-4" aria-hidden="true" />}
                    <span>{grouping[0].slice(3)}</span>
                  </div>
                  <div key={`${grouping[0]} content`} className="space-x-4 px-1">
                    {process?.steps.filter((step) => step.order >= 0).map((step) => (
                      <div className="inline-block" key={step.id}>
                        <RecruitingProcessStepCandidates
                          step={step}
                          candidates={candidatesForStepFromGrouping(step.id, grouping[1])}
                          onDrop={(item) => handleDrop(step.id, item)}
                          maxCandidates={!!selectedStep ? 0 : MAX_CANDIDATES}
                          isSelected={false}
                          anySelected={selectedStep !== undefined}
                          disabled={job?.open !== true}
                          loading={isSettingCandidateStep}
                          canManageCandidates={step.can_manage_candidates}
                          groupingName={grouping[0]}
                          hasHiringPerm={userData?.role === AccountRoles.HIRING_MANAGER && userData?.company?.id === job?.company?.id}
                          anyOverride={userData?.role === AccountRoles.COMPANY && userData?.company?.id === job?.company?.id}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </> : <div className="pt-4 space-x-4 px-1">
              {process?.steps.filter((step) => step.order >= 0).map((step) => (
                <div className="inline-block" key={step.id}>
                  <RecruitingProcessStep
                    onStepClick={() => {setSelectedStep(selectedStep?.id === step.id ? undefined : step)}}
                    step={step}
                    candidates={candidatesForStep(step.id)}
                    onDrop={(item) => handleDrop(step.id, item)}
                    maxCandidates={!!selectedStep ? 0 : MAX_CANDIDATES}
                    isSelected={selectedStep?.id === step.id}
                    anySelected={true}
                    disabled={job?.open !== true}
                    loading={isSettingCandidateStep}
                    canManageCandidates={step.can_manage_candidates}
                    groupingName={'combined'}
                    hasHiringPerm={userData?.role === AccountRoles.HIRING_MANAGER && userData?.company?.id === job?.company?.id}
                    anyOverride={userData?.role === AccountRoles.COMPANY && userData?.company?.id === job?.company?.id}
                    getGroupingNameForCandidate={getGroupingNameForCandidate}
                  />
                </div>
              ))}
            </div>}
          </HorizontalShadowScrollbars>

          {!!selectedStep && <RecruitingProcessStepDetail
            steps={process?.steps ?? []}
            step={selectedStep}
            candidates={candidatesForStep(selectedStep.id)}
            closeDetail={() => setSelectedStep(undefined)}
            reflectCandidateUpdate={reflectCandidateUpdate}
          />}
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default CompanyJobCandidates
