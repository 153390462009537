import { useState } from 'react'

import ProfileForm from "./forms/ProfileForm"
import ProfileLinkForm from './forms/ProfileLinkForm'
import ProfileLink from './links/ProfileLink'
import Modal from '../../modals/Modal'
import { valueOrNA } from '../../utils'
import WhiteButton from '../../buttons/WhiteButton'

import { accountService } from '../../../services'
import TealButton from '../../buttons/TealButton'
import { useEffect } from 'react'


const ProfileInfo = ({ person }: any) => {
  const [links, setLinks] = useState(person.links || [])
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const handleSubmit = (values: any, result: any) => {
    accountService.createLink(values).then((result: any) => {
      setLinks(links.concat([result.data]))
      setIsLinkDialogOpen(false)
    }).catch((error: any) => {
      console.error(error)
    })
  }

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    return () => {
      if (isEditing) setIsEditing(false)
    }
  })

  const deleteProfileLink = (deleteId: any) => {
    accountService.deleteLink(deleteId).then((result: any) => {
      if (result.success) {
        const newLinks = links.filter((itm: any) => itm.id !== deleteId)
        setLinks(newLinks)
      }
    }).catch((error: any) => {
      console.error(error)
    })
  }
  console.log('person', person)
  return (
    <>
      <div className="py-4">
        <TealButton
          title={isEditing ? 'Cancel': 'Edit'}
          className="profile-info-toggle-editing"
          onClick={() => { toggleIsEditing() }}
        />
      </div>
      {
        isEditing ?
          <ProfileForm toggleIsEditing={toggleIsEditing} person={person} />
          :
          <div>
            <Modal
              title="Add Profile Link"
              open={isLinkDialogOpen}
              setOpen={setIsLinkDialogOpen}
              hasForm={true}
            >
              <ProfileLinkForm handleSubmit={handleSubmit} />
            </Modal>

            <div className="grid grid-cols-1 gap-x-4 gap-y-8 p-3 sm:grid-cols-2 mt-4" data-tour="profileinfo">
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">Bio</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.bio)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.phone)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">Country</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.country)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">City</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.city?.title)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">State</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.state)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">Business Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.business_phone)}</dd>
              </div>
              <div className="sm:col-span1">
                <dt className="text-sm font-medium text-teal-600">Date of Birth</dt>
                <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.date_of_birth)}</dd>
              </div>
              <div className="sm:col-span1" data-tour="profilelinks">
                <dt className="text-sm font-medium text-teal-600">Links</dt>
                <dd>
                  {
                    links.length > 0 &&
                    <ul className="list-disc px-5">
                      {links.map((link: any) => (
                        <li key={link.id} className="my-2">
                          <ProfileLink object={link} deletable={true} onConfirm={() => deleteProfileLink(link.id)} />
                        </li>
                      ))}
                    </ul>
                  }
                  <WhiteButton
                    onClick={() => setIsLinkDialogOpen(true)}
                    title="Add Link"
                    className="mt-2"
                  />
                </dd>
              </div>

            </div>
          </div>
      }
    </>
  )
}
export default ProfileInfo
